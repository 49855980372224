@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.woc-shimmer {
  animation: shimmer linear 2s infinite;
  background: #FFF, linear-gradient(to right, #E5E5E5 8%, #F0F0F0 18%, #E5E5E5 33%);
  background-size: 1200px 100%;
}