@keyframes player-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--accent-500-rgb), 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--accent-500-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--accent-500-rgb), 0);
  }
}

.woc-player-button {
  animation: player-pulse 2s infinite;
  appearance: none;
  box-shadow: 0 0 0 rgba(var(--accent-500-rgb), 0.4);
  display: inline-flex;
}
