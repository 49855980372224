@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--accent-900-rgb), 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--accent-900-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--accent-900-rgb), 0);
  }
}

.woc-active-stream {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(234, 179, 8, 0.4);
}

@keyframes highlight-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(234, 179, 8, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(234, 179, 8, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(234, 179, 8, 0);
  }
}

.woc-active-highlight-stream {
  animation: highlight-pulse 2s infinite;
  box-shadow: 0 0 0 rgba(234, 179, 8, 0.4);
}

.woc-highlight-label {
  margin-left: auto !important;
}