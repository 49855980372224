*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: "Source Sans 3", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: JetBrains Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/2 {
  top: 50%;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.right-5 {
  right: 1.25rem;
}

.top-5 {
  top: 1.25rem;
}

.-right-24 {
  right: -6rem;
}

.-top-8 {
  top: -2rem;
}

.bottom-0 {
  bottom: 0;
}

.top-8 {
  top: 2rem;
}

.-top-36 {
  top: -9rem;
}

.-bottom-52 {
  bottom: -13rem;
}

.-left-40 {
  left: -10rem;
}

.bottom-1\/2 {
  bottom: 50%;
}

.-left-4 {
  left: -1rem;
}

.-right-4 {
  right: -1rem;
}

.-left-6 {
  left: -1.5rem;
}

.top-3 {
  top: .75rem;
}

.right-1 {
  right: .25rem;
}

.-bottom-24 {
  bottom: -6rem;
}

.bottom-4 {
  bottom: 1rem;
}

.left-2 {
  left: .5rem;
}

.top-2 {
  top: .5rem;
}

.right-2 {
  right: .5rem;
}

.-right-\[1px\] {
  right: -1px;
}

.-z-10 {
  z-index: -10;
}

.z-\[99999\] {
  z-index: 99999;
}

.z-\[9999\] {
  z-index: 9999;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[99997\] {
  z-index: 99997;
}

.z-\[99998\] {
  z-index: 99998;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.m-5 {
  margin: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 1rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-5 {
  margin-top: 1.25rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-auto {
  margin-top: auto;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.\!hidden {
  display: none !important;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-96 {
  height: 24rem;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-40 {
  height: 10rem;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-14 {
  height: 3.5rem;
}

.h-64 {
  height: 16rem;
}

.h-48 {
  height: 12rem;
}

.h-0\.5 {
  height: .125rem;
}

.h-0 {
  height: 0;
}

.h-8 {
  height: 2rem;
}

.h-\[52px\] {
  height: 52px;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-10 {
  height: 2.5rem;
}

.h-\[400px\] {
  height: 400px;
}

.h-20 {
  height: 5rem;
}

.h-5 {
  height: 1.25rem;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-4\/5 {
  width: 80%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-\[400px\] {
  width: 400px;
}

.w-40 {
  width: 10rem;
}

.w-\[356px\] {
  width: 356px;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-\[78px\] {
  width: 78px;
}

.w-12 {
  width: 3rem;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-lg {
  max-width: 32rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/3 {
  --tw-translate-y: 33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-3 {
  --tw-rotate: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-\[10deg\] {
  --tw-rotate: -10deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-2 {
  --tw-rotate: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-12 {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-6 {
  --tw-rotate: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-\[10deg\] {
  --tw-skew-x: -10deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-12 {
  --tw-skew-x: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-3 {
  --tw-skew-x: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-6 {
  --tw-skew-x: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-100 {
  --tw-scale-x: -1;
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-b {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-b-md {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-aqua-900 {
  --tw-border-opacity: 1;
  border-color: rgb(24 107 110 / var(--tw-border-opacity));
}

.border-\[\#EAB308\] {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-arctic-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(142 218 250 / var(--tw-bg-opacity));
}

.bg-accent-500 {
  background-color: var(--accent-500);
}

.bg-persian-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 228 232 / var(--tw-bg-opacity));
}

.bg-aqua-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 253 236 / var(--tw-bg-opacity));
}

.bg-lavender-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 229 254 / var(--tw-bg-opacity));
}

.bg-aqua-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 107 110 / var(--tw-bg-opacity));
}

.bg-lavender-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(193 122 242 / var(--tw-bg-opacity));
}

.bg-arctic-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 250 254 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-arctic-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 254 254 / var(--tw-bg-opacity));
}

.bg-\[\#EAB308\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-to: #0000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lavender-200 {
  --tw-gradient-from: #f1cbfd;
  --tw-gradient-to: #f1cbfd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-arctic-500\/10 {
  --tw-gradient-to: #8edafa00;
  --tw-gradient-stops: var(--tw-gradient-from), #8edafa1a, var(--tw-gradient-to);
}

.via-lavender-500\/10 {
  --tw-gradient-to: #c17af200;
  --tw-gradient-stops: var(--tw-gradient-from), #c17af21a, var(--tw-gradient-to);
}

.to-arctic-900\/90 {
  --tw-gradient-to: #1b4377e6;
}

.to-lavender-900\/90 {
  --tw-gradient-to: #381774e6;
}

.to-arctic-200 {
  --tw-gradient-to: #d2fafe;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-1 {
  padding: .25rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-\[70px\] {
  padding-bottom: 70px;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.font-round2 {
  font-family: PilcrowRounded, sans-serif;
}

.font-pally {
  font-family: Pally, sans-serif;
}

.font-handwriting {
  font-family: Caveat, sans-serif;
}

.font-round {
  font-family: Quicksand, sans-serif;
}

.font-brush {
  font-family: Explorer, sans-serif;
}

.font-fat {
  font-family: Lilita One, sans-serif;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-accent-900 {
  color: var(--accent-900);
}

.text-accent-500 {
  color: var(--accent-500);
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-arctic-500 {
  --tw-text-opacity: 1;
  color: rgb(142 218 250 / var(--tw-text-opacity));
}

.text-persian-900 {
  --tw-text-opacity: 1;
  color: rgb(118 23 111 / var(--tw-text-opacity));
}

.text-aqua-500 {
  --tw-text-opacity: 1;
  color: rgb(127 230 193 / var(--tw-text-opacity));
}

.text-aqua-900 {
  --tw-text-opacity: 1;
  color: rgb(24 107 110 / var(--tw-text-opacity));
}

.text-lavender-500 {
  --tw-text-opacity: 1;
  color: rgb(193 122 242 / var(--tw-text-opacity));
}

.text-lavender-900 {
  --tw-text-opacity: 1;
  color: rgb(56 23 116 / var(--tw-text-opacity));
}

.text-persian-500 {
  --tw-text-opacity: 1;
  color: rgb(246 122 188 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-arctic-900 {
  --tw-text-opacity: 1;
  color: rgb(27 67 119 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-arctic-600 {
  --tw-text-opacity: 1;
  color: rgb(103 174 215 / var(--tw-text-opacity));
}

.text-\[\#EAB308\] {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.text-accent-100 {
  color: var(--accent-100);
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-aqua-100 {
  --tw-text-opacity: 1;
  color: rgb(231 253 236 / var(--tw-text-opacity));
}

.text-lavender-800 {
  --tw-text-opacity: 1;
  color: rgb(81 38 140 / var(--tw-text-opacity));
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.opacity-50 {
  opacity: .5;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-arctic-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 174 215 / var(--tw-ring-opacity));
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.woc-accent-persian {
  --accent-100: #fee4e8;
  --accent-100-rgb: 254, 228, 232;
  --accent-200: #fecad6;
  --accent-200-rgb: 254, 202, 214;
  --accent-300: #fcafc9;
  --accent-300-rgb: 252, 175, 201;
  --accent-400: #f99bc4;
  --accent-400-rgb: 249, 155, 196;
  --accent-500: #f67abc;
  --accent-500-rgb: 246, 122, 188;
  --accent-600: #d359a6;
  --accent-600-rgb: 211, 89, 166;
  --accent-700: #b13d92;
  --accent-700-rgb: 177, 61, 146;
  --accent-800: #8e267d;
  --accent-800-rgb: 142, 38, 125;
  --accent-900: #76176f;
  --accent-900-rgb: 118, 23, 111;
}

.woc-accent-aqua {
  --accent-100: #e7fdec;
  --accent-100-rgb: 231, 253, 236;
  --accent-200: #cffcde;
  --accent-200-rgb: 207, 252, 222;
  --accent-300: #b4f7d2;
  --accent-300-rgb: 180, 247, 210;
  --accent-400: #9ff0cb;
  --accent-400-rgb: 159, 240, 203;
  --accent-500: #7fe6c1;
  --accent-500-rgb: 127, 230, 193;
  --accent-600: #5cc5aa;
  --accent-600-rgb: 92, 197, 170;
  --accent-700: #40a595;
  --accent-700-rgb: 64, 165, 149;
  --accent-800: #28857f;
  --accent-800-rgb: 40, 133, 127;
  --accent-900: #186b6e;
  --accent-900-rgb: 24, 107, 110;
}

.woc-accent-arctic {
  --accent-100: #e8fefe;
  --accent-100-rgb: 232, 254, 254;
  --accent-200: #d2fafe;
  --accent-200-rgb: 210, 250, 254;
  --accent-300: #bbf2fd;
  --accent-300-rgb: 187, 242, 253;
  --accent-400: #aae9fb;
  --accent-400-rgb: 170, 233, 251;
  --accent-500: #8edafa;
  --accent-500-rgb: 142, 218, 250;
  --accent-600: #67aed7;
  --accent-600-rgb: 103, 174, 215;
  --accent-700: #4785b3;
  --accent-700-rgb: 71, 133, 179;
  --accent-800: #2d5f90;
  --accent-800-rgb: 45, 95, 144;
  --accent-900: #1b4377;
  --accent-900-rgb: 27, 67, 119;
}

.woc-accent-lavender {
  --accent-100: #f9e5fe;
  --accent-100-rgb: 249, 229, 254;
  --accent-200: #f1cbfd;
  --accent-200-rgb: 241, 203, 253;
  --accent-300: #e5b0fb;
  --accent-300-rgb: 229, 176, 251;
  --accent-400: #d69bf7;
  --accent-400-rgb: 214, 155, 247;
  --accent-500: #c17af2;
  --accent-500-rgb: 193, 122, 242;
  --accent-600: #9859d0;
  --accent-600-rgb: 152, 89, 208;
  --accent-700: #733dae;
  --accent-700-rgb: 115, 61, 174;
  --accent-800: #51268c;
  --accent-800-rgb: 81, 38, 140;
  --accent-900: #381774;
  --accent-900-rgb: 56, 23, 116;
}

.woc-accent-neutral {
  --accent-100: #f5f5f5;
  --accent-100-rgb: 245, 245, 245;
  --accent-200: #e5e5e5;
  --accent-200-rgb: 229, 229, 229;
  --accent-300: #d4d4d4;
  --accent-300-rgb: 212, 212, 212;
  --accent-400: #a3a3a3;
  --accent-400-rgb: 163, 163, 163;
  --accent-500: #737373;
  --accent-500-rgb: 115, 115, 115;
  --accent-600: #525252;
  --accent-600-rgb: 82, 82, 82;
  --accent-700: #404040;
  --accent-700-rgb: 64, 64, 64;
  --accent-800: #262626;
  --accent-800-rgb: 38, 38, 38;
  --accent-900: #171717;
  --accent-900-rgb: 23, 23, 23;
}

.woc-html a {
  color: var(--accent-500);
  text-underline-offset: 2px;
  text-decoration: underline;
}

.hover\:-mx-2\.5:hover {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.hover\:-mx-2:hover {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.hover\:-mx-5:hover {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.hover\:bg-accent-200:hover {
  background-color: var(--accent-200);
}

.hover\:bg-aqua-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(24 107 110 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-aqua-500:hover {
  --tw-text-opacity: 1;
  color: rgb(127 230 193 / var(--tw-text-opacity));
}

.hover\:text-lavender-500:hover {
  --tw-text-opacity: 1;
  color: rgb(193 122 242 / var(--tw-text-opacity));
}

.hover\:text-persian-500:hover {
  --tw-text-opacity: 1;
  color: rgb(246 122 188 / var(--tw-text-opacity));
}

.hover\:text-arctic-500:hover {
  --tw-text-opacity: 1;
  color: rgb(142 218 250 / var(--tw-text-opacity));
}

.hover\:text-neutral-500:hover {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.hover\:text-mustard-500:hover {
  --tw-text-opacity: 1;
  color: rgb(255 243 112 / var(--tw-text-opacity));
}

.group:hover .group-hover\:mb-8 {
  margin-bottom: 2rem;
}

.group:hover .group-hover\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:rounded-b-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.group:hover .group-hover\:text-aqua-300 {
  --tw-text-opacity: 1;
  color: rgb(180 247 210 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:static {
    position: static;
  }

  .md\:top-10 {
    top: 2.5rem;
  }

  .md\:-left-40 {
    left: -10rem;
  }

  .md\:-right-40 {
    right: -10rem;
  }

  .md\:right-10 {
    right: 2.5rem;
  }

  .md\:bottom-10 {
    bottom: 2.5rem;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .md\:mb-1 {
    margin-bottom: .25rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:max-w-screen-sm {
    max-width: 640px;
  }

  .md\:translate-y-1\/4 {
    --tw-translate-y: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:rounded-md {
    border-radius: .375rem;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 1024px) {
  .lg\:w-1\/6 {
    width: 16.6667%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:grid {
    display: grid;
  }

  .xl\:w-\[12\.5\%\] {
    width: 12.5%;
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:w-1\/3 {
    width: 33.3333%;
  }

  .\32 xl\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .\32 xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.woc-shimmer {
  background-color: #0000;
  background-image: none, linear-gradient(to right, #e5e5e5 8%, #f0f0f0 18%, #e5e5e5 33%);
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: 1200px 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  animation: shimmer 2s linear infinite;
}

@keyframes player-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--accent-500-rgb), .7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(var(--accent-500-rgb), 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(var(--accent-500-rgb), 0);
  }
}

.woc-player-button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: 0 0 0 rgba(var(--accent-500-rgb), .4);
  animation: player-pulse 2s infinite;
  display: inline-flex;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--accent-900-rgb), .7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(var(--accent-900-rgb), 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(var(--accent-900-rgb), 0);
  }
}

.woc-active-stream {
  animation: pulse 2s infinite;
  box-shadow: 0 0 #eab30866;
}

@keyframes highlight-pulse {
  0% {
    box-shadow: 0 0 #eab308b3;
  }

  70% {
    box-shadow: 0 0 0 10px #eab30800;
  }

  100% {
    box-shadow: 0 0 #eab30800;
  }
}

.woc-active-highlight-stream {
  animation: highlight-pulse 2s infinite;
  box-shadow: 0 0 #eab30866;
}

.woc-highlight-label {
  margin-left: auto !important;
}

@keyframes meter {
  0% {
    background-position-x: -34px;
  }

  to {
    background-position-x: 0;
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.woc-donation-amount.is-current {
  animation: tada 2s linear;
  display: inline-block;
}

.woc-donation-meter {
  transition: width 4s cubic-bezier(.85, 0, .15, 1);
  animation: meter 2s linear infinite;
}

/*# sourceMappingURL=index.79905f0f.css.map */
